.container {
  flex: 1;
  display: flex;
  height: 100vh;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-right {
  position: fixed;
  width: 250px;
  height: 140px;
  top: 40px;
  right: 40px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}

@media (max-width: 600px) {
  .icon-right {
    display: none;
  }
}
