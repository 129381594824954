.logoImg {
  width: '100%';
  margin: 20px;
  max-width: 200px;
  height: 'auto';

}
@media (max-width: 700px) {
 .logoImg {
   display: none;
 }
}
